import React from "react";
import Card from "./Card";
import EmbedYoutube from './Youtube';
import Footer from "./Footer";
import { useEffect } from "react";


export default function Products() {
  useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  return (
    <>
      <div className="content-wrap">
        <div className="divider-wrap">
          <h1>Door Solutions</h1>
        </div>
        <div className='divider-subhead'>
          <div className='divider-subtext'>
            <p>
              Glo Fenestration doors provide beauty, high performance, multiple design options and numerous configurations. Our doors have excellent performance and quality. European hardware ensures smooth operation and impeccable style. Glo doors achieve better R values and offer sleek architectural lines that are ideal for contemporary and traditional projects.
            </p>
          </div>
          <div className='divider-line' />
        </div>
        <div className='card-container'>
          <Card
            id="prod_1"
            image='https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65a82c603519353ccafb5e26_glofenestration_cloudhouse_swingdoor-p-2000.jpg'
            imageTitle="Glo Swing Door System"
            href="https://glo-windows-doors.webflow.io/product-categories/swing-doors"
            series="Swing"       
            content="Glo high-performance aluminum swing doors combine precision engineered European components, operational excellence, and superior thermal efficiency to provide not only an architecturally appealing entrance but also one that is highly energy efficient to enhance the overall building envelope performance."
            links={{
              "Single Swing": "https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65dc4ef7bcf25c5803e0f85d_Glo_Configuration%20Drawings_Square_v011%20Fixed_Single%20Swing-p-500.jpg",
              "French Swing": "https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65dc4f07aae68a28aeffcf46_Glo_Configuration%20Drawings_Square_v011%20Fixed_French-06-p-500.jpg",
              "Single Swing + Sidelight": "https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65dc4f32e9c690b82ffa52b5_Glo_Configuration%20Drawings_Square_v011%20Fixed_Single%20Swing%20%2B%20Sidelight-p-500.jpg",
              "Single Swing + Sidelights": "https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65dc4f408f8db64920103b39_Glo_Configuration%20Drawings_Square_v011%20Fixed_Single%20Swing%20%2B%20Sidelights-p-500.jpg",
              "Single Swing + Sidelights + Toplight":"https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65dc4f4e2a1caf54ac6deb71_Glo_Configuration%20Drawings_Square_v011%20Fixed_French%20copy%202-p-500.jpg",
              "Single Swing + Sidelights + Toplights":"https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65dc4f5caab3c301982376e0_Glo_Configuration%20Drawings_Square_v011%20Fixed_French%20copy%203-p-500.jpg"
            }}  
          />
          <Card
            id="prod_2"
            image='https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65b2a8b4f7f56b03d2f39652_1.jpg'
            imageTitle="Pivot"
            href="https://glo-windows-doors.webflow.io/product-categories/pivot"
            series="Pivot"
            content="Discover the epitome of performance and sophistication with our Swiss-engineered pivot door, an innovative design offered in conjunction with our partners Air Lux.
             Showcasing unparalleled attention to detail, expansive sizes, and the ability to seamlessly integrate into building structures, Glo pivot doors make a bold statement in any architectural setting."
            links={{
              "Single Pivot": "https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/659ef4937f09823255c1e874_Glo_Configuration%20Drawings_Square_v06_Tilt%20%26%20Turn-p-500.jpg",
              "Single Pivot + Sidelight":"https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/659ef45fdf5e12fdcffe58c3_Glo_Configuration%20Drawings_Square_v06_Single%20Tilt%20%26%20Turn-p-500.jpg",
              "French Pivot":"https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/658e8ca620697f6f9d3e8269_Glo_Configuration%20Drawings_Square_v06_Double%20Doors%20Tilt%20%26%20Turn-p-500.jpg"
            }}  
         />
          <Card
            id="prod_3"
            image='https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65a827064425126bec59c76b_glofenestration_airlux_slidingdoor_night-p-2000.jpg'
            imageTitle='Glo Sliding Door Series'
            href='https://glo-windows-doors.webflow.io/product-categories/air-lux-sliding'
            series='Sliding'
            content="Step into the future of sliding door design and functionality with our Swiss-engineered sliding door, a masterpiece of precision and innovation offered in cooperative with our Swiss partner Air Lux. Designed to impress with unrivaled sizes, it seamlessly integrates into a range of unique architectural designs, offering a modern and visually sophisticated look. Setting itself apart from comparable sliding solutions is a proprietary air and water sealing system, ensuring unparalleled protection against the elements while contributing to the overall energy efficiency of your space."
            links={{
              "Single Panel": "https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65af78b09d5e582e4341010d_Glo_Configuration%20Drawings_Square_v011_1-p-500.jpg",
              "Pocketing":"https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65af78a51324ff28c877cbee_Glo_Configuration%20Drawings_Square_v011_a%20pocket-p-500.jpg",
              "Center Outward Opening":"https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65af78994de40cb59bd82eb4_Glo_Configuration%20Drawings_Square_v011_c-p-500.jpg",
              "Postless Corner":"https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65af788dfa6c075c7f09dbaf_Glo_Configuration%20Drawings_Square_v011_c11-p-500.jpg",
              "Single Panel + Sidelights":"https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65af787282c75aa35f4f4c27_Glo_Configuration%20Drawings_Square_v011_g-p-500.jpg",
              "Center Inward Opening":"https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65af78584f71ad3aafd9239b_Glo_Configuration%20Drawings_Square_v011_k-p-500.jpg"
            }}  
         />
        </div>
        <div className="divider-wrap">
          <h1>Window Solutions</h1>
        </div>
        <div className="divider-subhead">
          <div className="divider-subtext">
          </div>
          <div className="divider-line" />
        </div>
        <div className="card-container">
          <Card
            id="prod_4"
            image='https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65ab112cac57fb6588b2ae88_39-Robertson_Fall_2022-p-800.jpg'
            imageTitle="Glo Windows"
            href="https://www.glofenestration.com/product-categories/windows"
            series="Windows"
            content="Glo windows are engineered and fabricated to deliver the perfect blend of architectural aesthetic and thermal performance while providing a contemporary look and improving building envelope performance. Available in a variety of configurations and finish options, we work closely with architects on a per project basis to ensure your window package is custom tailored to meet unique architectural needs and performance goals."
            links={{
              "Fixed": "https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65af78e0c750891066dfbaa8_Glo_Configuration%20Drawings_Square_v011_Fixed-p-500.jpg",
              "Tilt + Turn":"https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65af78eb5a121f40c9d95260_Glo_Configuration%20Drawings_Square_v011_Tilt%20Turn-p-500.jpg",
              "Tilt Only":"https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65af78678456f412e05dc1d3_Glo_Configuration%20Drawings_Square_v011_Hopper%20-%20Awning-p-500.jpg",
              "French":"https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65af78d4e8107f767f6e20cd_Glo_Configuration%20Drawings_Square_v011_French-08-p-500.jpg",
              "Sliding + Pocketing":"https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65dc50c074ddb235b8eebe9b_Glo_Configuration%20Drawings_Square_v011%20Sliding%20Window_French%20copy%204-p-500.jpg"
            }}  
          />
          <Card
            id="prod_5"
            image='https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65a822f06640c07d239c27e4_glofenestration_cloudhouse_curtainwall_day-p-2000.jpg'
            imageTitle="Glo Curtain Wall"
            href="https://www.glofenestration.com/product-categories/curtain-wall"
            series="Curtain Wall"
            content="The Glo high-performance thermally broken aluminum curtain wall system is an advanced architectural solution designed to enhance energy efficiency and thermal insulation in buildings while creating large expanses of glass for epic views. The aluminum framework provides structural strength and durability while the thermal break ensures that the curtain wall system effectively mitigates thermal bridging, maintaining a comfortable indoor environment and adding a positive contribution to the performance of the building envelope. This system is ideal for modern, energy-efficient structures seeking the greatest architectural aesthetics, superior thermal insulation, and incredible expanses of glass."
            links={{
              "Curtain Wall" : "https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/658d8b3d5a05f359b1e908ad_Glo_Configuration%20Drawings_Square_v02_Curtain%20Wall-p-500.jpg",
              "Curatin Wall + Operables" : "https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/658d8b466f7007cb92c64543_Glo_Configuration%20Drawings_Square_v02_Curtain%20Wall%20w%20Operables-p-500.jpg"
            }}  
         />
          <Card
            id="prod_6"
            image='https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/65a85a088c5d458ed00a203a_glofenestration_airlux_descending_poolhouse-p-2000.jpg'
            imageTitle="Glo Descending Wall"
            href="https://www.glofenestration.com/product-categories/ascending-descending"
            series="Descending Wall"
            content="The paragon of architectural innovation and luxury, the Swiss-engineered Descending Wall combines cutting-edge air water sealing technology with the capability to open the worlds largest pieces of glass. Offering grandeur in sizable proportions and limited only by the size of available glass, the Descending Wall redefines the boundaries of indoor-outdoor living. Its innovative air water sealing system ensures a seamless transition between spaces while providing unparalleled protection against the elements. Elevate your living experience with a touch of Swiss craftsmanship, where precision fabrication meets avant-garde design in this remarkable descending glass wall, setting a new standard for contemporary living."
            links={{
              "Ascending" : "https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/658e8c587aa90abd23335f12_Glo_Configuration%20Drawings_Square_v06_Ascending-p-500.jpg",
              "Descending" : "https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/658e8c68754826eebe82eb34_Glo_Configuration%20Drawings_Square_v06_Descending-p-500.jpg"
            }}  
         />

        </div>
        <div className="divider-wrap">
          <h1>Product Highlights</h1>
        </div>
        <div className='project-content-wrap'>
          <EmbedYoutube source="https://www.youtube.com/embed/S7ARluoVDSw" height='600' width='800' />
          <EmbedYoutube source="https://www.youtube.com/embed/8UlBXOrn9Fo" height='600' width='800' />
        </div>
      </div>
      <div id='home-footer-two'>
        <Footer />
      </div>
    </>
  )
}
