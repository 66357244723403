import React from 'react'
import '../Styles/card.scss'


export default function Card(props) {
  const ids = Object.keys(props.links);
  const refs= Object.values(props.links);


  return (
    <>
      <div className='card-wrap'>
        <a href={props.href} target="_blank" rel='noreferrer'>
          <img className='card-img' src={props.image} alt={props.title}/>
          <div className='card-content'>
            <h2 className='card-title'>{props.series}</h2>
            <h5 className='card-subtitle'>{props.title}</h5>
            <p className='card-text'>{props.content}</p>
            <div className='card-detail-group-wrap'>
            {
              ids.map(link => {
                const index = ids.indexOf(link)
                const keyGen = (ids.indexOf(link) + 1)
                const title = ids[index]
                const hyperlink = refs[index]
                return(
                  <div key={keyGen} className="card-detail-image">
                    <img key={index} src={hyperlink} alt={title}/>
                  </div>
                )
              })
          }
            </div>
          </div>
        </a>
      </div>
    </>
  )
}