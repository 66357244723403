
import WindowProvider from './Components/Providers/WindowProvider';
import Main from './Components/Main';


function App() {
  return (
    <WindowProvider>
          <Main />
    </WindowProvider>
  );
}

export default App;
