import React from 'react';
import Logo from '../images/Insïght LOGO no lines.jpg';
import Tabs from '../Components/Tabs';
import GloLogo from '../images/Glo_Fenestration_Logo_1_line.png';
import AirluxLogo from '../images/airluxLogo.png';

export default class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fade: "fade-out",
      tabFade: "fade-out",
      imgClass: "logo-img-startup",
      display: "none"
    }
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        width: "100%",
        imgClass: "logo-img",
        display: "flex"
      });
    }, 1000);

    setTimeout(() => {
      this.setState({
        fade: "fade-in",
      });
    }, 2100);

    setTimeout(() => {
      this.setState({
        tabFade: "fade-in",
      });
    }, 2700);

  }

  render() {
      return (
        <div className="head-container">
          <img src={Logo} className={this.state.imgClass} alt="logo" />
          <div className={this.state.tabFade}>
            <Tabs active={this.props.active} setSelected={this.props.setSelected} tabs={this.props.tabs} fade={this.state.tabFade} />
          </div>
          <div className={"headTextWrap " + this.state.fade} style={{ display: this.state.display }}>
            <h2 className="headText">The exclusive Utah source for Glo Fenestration and Air-Lux</h2>
            <div id="small-logo-wrap">
              <a href="https://glowindows.com/" target='_blank' rel='noreferrer'>
                <img src={GloLogo} className="small-logo-img" id="nav-glo-logo" alt="Glo Logo"/>
              </a>
              <a href="https://www.air-lux.com/" target='_blank' rel='noreferrer'>
                <img src={AirluxLogo} className="small-logo-img" alt="Air-lux Logo"/>
              </a>
            </div>
          </div>
        </div>
      )
    }
  }








