import React from 'react';
import Logo from '../images/Insïght LOGO no lines.jpg';
import Tabs from '../Components/Tabs';
import GloLogo from '../images/Glo_Logo.png';
import AirluxLogo from '../images/airluxLogo.png'



export default function MobileNav(props) {
  return(
    <div className='mobile-nav-wrap'>
    <img src={Logo} className='mobile-logo' alt="logo" />
    <div className='headText'>
      <h2>The exclusive Utah source for Glo Fenestration and Air-Lux</h2>
      <div id="mobile-logo-wrap">
        <a href="https://glowindows.com/" target='_blank' rel='noreferrer'>
          <img src={GloLogo} className="small-logo-img" alt="Glo Logo" id="mobile-glo-logo" />
        </a>
        <a href="https://www.air-lux.com/" target='_blank' rel='noreferrer'>
          <img src={AirluxLogo} className="small-logo-img" alt="Air-lux Logo" id="mobile-airlux-logo"/>
        </a>
      </div>
    <div className='mobile-tab-wrap'>
      <Tabs active={props.active} setSelected={props.setSelected} tabs={props.tabs} />
    </div>
    </div>
  </div>
  );
}