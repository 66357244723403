import React from 'react'


export default function Footer() {


  return (
    <>
      <div id="lower-footer">
        <div id="contact-wrap">
          <h5 className="contact-label">Mailing</h5>
          <p className="contact-text">
            PO Box 980547<br />
            Park City, Utah 84098
          </p>
          <h5 className="contact-label">Showroom</h5>
          <p className="contact-text">
            4376 N Forestdale Dr #12<br />
            Park City, Utah 84098
          </p>
          <div className="email-wrap">
            <a className="email-link" href="mailto:travis@tandtmountainbuilders.com">travis@insighteuropeanwindows.com</a>
          </div>
        </div>
        <div className="map-container">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3025.118626322351!2d-111.46783198459562!3d40.69338497933395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x875212d0501c7f27%3A0xfe41af1d32eb6996!2s4376%20N%20Forestdale%20Dr%20%2312%2C%20Park%20City%2C%20UT%2084098!5e0!3m2!1sen!2sus!4v1597360190248!5m2!1sen!2sus" width="600" height="300" frameBorder="0" title="office" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
        </div>
      </div>
      <div id="copywright">
        <p> Created by <a href="http://www.grizzcode.com">GrizzCODE</a> © 2023 </p>
      </div>
    </>
  )
}