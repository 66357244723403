import React from 'react';

export default class TabNav extends React.Component {
  render() {
    return (
      <>
        <div className="tab-container">
            {
              this.props.tabs.map(tab => {
                const active = (tab === this.props.active ? 'active ' : '');
                return (
                  <div className={"tab " + active}  key={tab}>
                    <h4 onClick={() => this.props.setSelected(tab)}>
                      {tab}
                    </h4>
                  </div>
                );
              })
            }
          </div>
        {this.props.children}
      </>
    );
  }
}
