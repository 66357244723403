import React from 'react'
import { useWindowDimensions } from './Providers/WindowProvider'

export default function EmbedYoutube(props) {

  const window = useWindowDimensions();
  let height = props.height
  let  width = props.width
  const aspectRatio = 0.5625
  const source = props.source

  if (window.width < 1320) {
    width = window.width;
    height = width * aspectRatio;
  }

  return (
    <div className='youtube'>
      <iframe width={width} height={height} src={source} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </div>
  )
}