import React, { useEffect } from 'react'

import Footer from './Footer';

export default function Technical() {
  useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  return (
    <>
      <div className='divider-wrap'>
        <h1>Detail Drawings & Information</h1>
      </div>
      <div className='technical-wrap'>
        <img src="https://cdn.prod.website-files.com/64707f1ad45e8664ecdf3ffa/658d8899e7844e205d2ec08d_image-cmcid-103463--data.jpg" alt="Window Cross Section" id="technical-hero-img"></img>
        <div id="technical-text">
            <p>
              Designers and clients seeking detailed technical information about Glo Fenestration products can contact us to obtain the correct files and information. We want to be involved in the process to ensure accurate information is being recieved and utilized, to answer any questions about the pertinent information, and to advise on important details. 
            </p>
            <p>
              To obtain technical documents including CAD files, certifications, performance specifications and installation details, please contact us directly. We will promptly provide the most accurate and current information avaliable. 
            </p>
            <p>
            <a href="mailto:tech@insighteuropeanwindows.com">office@insighteuropeanwindows.com</a>
            </p>
          </div>
      </div>

      <div id='home-footer-two'>
        <Footer />
      </div>
    </>
  );
}