import React, { useState } from 'react';
import Navbar from '../Components/Nav'
import Home from '../Components/Home'
import Technical from './Technical';
import { useWindowDimensions } from './Providers/WindowProvider'
import MobileNav from './MobileNav';
import Products from './Products';
import Service from './Service';

export default function Main() {

  const [active, setActive] = useState("Home");
  const window = useWindowDimensions();
  const tabList = ["Home", "Products", "Technical", "Service"];
  

  const renderNav = () => {
    
    if (window.width < 1320) {
      return (
        <MobileNav 
        setSelected={setActive}
        active={active}
        tabs={tabList}
        />
      );
    } else {
      return (
        <Navbar 
        setSelected={setActive}
        active={active}
        tabs={tabList}
        />
      );
    }
  }

  const renderPage = () => {
    if (active === "Home") {
      return (
        <Home />
      )
    } else if (active === "Technical") {
      return (
        <Technical />
      )
    } else if (active === "Products") {
      return (
        <Products />
      )
    } else if (active === "Service") {
      return (
        <Service />
      )
    }
  }


    return (
      <>
        {renderNav()}
        {renderPage()}
      </>
    )
  }

